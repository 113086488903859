.container-detail-product .box {
    width: 80%;
    display: flex;
    margin: 0 auto;
    border-radius: 4px;
  
}

.container-detail-product .box-1 {
    width: 70%;
}

.container-detail-product .box-1  .container-imgct{
   width: 100%;
   height: 14vh;
   display: flex;
   margin: 0 auto;
   justify-content: center;
   align-items: center;
  
}

.container-detail-product .box-1  .container-imgct .box-img-phu{
   width: 10%;
   height: 90%;
    cursor: pointer;
   margin: 0 20px;
   
 }

 .container-detail-product .box-1  .container-imgct .box-img-phu img{
   width: 100%;
  }
 
.container-detail-product .box-1 ul li {
    display: inline-block;
    padding: 5px;
    background-color: black;
    color: white;
}

.container-detail-product .box-1 .img {
    width: 35%;
    margin: 0 auto;
}

.container-detail-product .img img {
    width: 100%;
}

.container-detail-product .box-2 {
    width: 30%;
}

.container-detail-product .box-2__price {
    text-align: center;
    margin: 10px;
}

.container-detail-product .box-2__color,
.container-detail-product .box-2__size {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-detail-product .box-2__name{
    text-align: center;
    font-weight: 200;
}
.container-detail-product .box-2__color button {
    margin: 0 4px 0px 0px;
}

.container-detail-product .box-2__size {
    justify-content: center;
}

.container-detail-product .box-2__color button,
.container-detail-product .box-2__size button {
    width: 24%;
    padding: 13px 0px;
    border: 1px solid;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    margin: 0 5px;
}

.container-detail-product .add:hover {
    background-color: black;
    color: white;
}


.container-detail-product .box-2__size {
    margin-top: 10px;
}

.container-detail-product .box-2 .add,
.container-detail-product .buy {
    padding: 12px;
    width: 100%;
    text-align: center;
    border: 1px solid;
    border-radius: 2px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
}

.container-detail-product .box-2 .buy {
    background-color: black;
    color: white;
}

.container-detail-product .box-2 .buy:hover {
    opacity: 0.7;
}


.container-detail-product .box-2__detail {
    margin-top: 10px;
}

.container-detail-product .box-2__detail ul {
    margin-left: 20px;
}

.container-detail-product .box-2__detail ul li {
    line-height: 20px;
}

@media (max-width:1024px) {
    .container-detail-product .box {
        flex-direction: column;
    }

    .container-detail-product .box-1 {
        width: 100%;
    }

    .container-detail-product .box-2 {
        width: 100%;
    }

    .container-detail-product .box-2__color button {
        margin: 0 9px 0px 0px;
    }
}

@media (max-width: 767px) {
    .container-detail-product .box-2__color button {
        margin: 0 6px 0px 0px;
    }
}