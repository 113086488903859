

.form-quenmatkhau  {
    width: 50%;
    margin: 40px auto;
}

.form-quenmatkhau h3,
h2 {
    font-weight: 700;
    color: black;
    margin: 10px 0;
}


.form-quenmatkhau input[type="text"] {
    display: block;
    margin: 25px 0;
    padding: 15px;
    width: 100%;
    background-color: #ededed;
    border: none;
    color: gray;
    outline: none; 
    transition: 0.4s;
}

.form-quenmatkhau input[type="text"]:focus {
    background-color: #ededed45;
    border: 0.5px solid rgba(128, 128, 128, 0.599);
}
.form-quenmatkhau button {
    padding: 15px 25px;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 0 0 0 white;
}

.form-quenmatkhau button.box__sign-in {
    background-color: black;
    color: white;
}

.form-quenmatkhau button:hover {
    cursor: pointer;
    color: black;
    background-color: white;
    border: 1px solid;
    box-shadow: 0 0 20px 10px white;
}

.form-quenmatkhau button.box__close {
    background-color: white;
}



@media (max-width: 739px) {
    .form-quenmatkhau {
        width: 80%;
    }

    .form-quenmatkhau button {
        width: 100%;
    }

    .form-quenmatkhau .box__close {
        margin-top: 10px;
    }
}