@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  
  font-family: 'Montserrat', sans-serif;
  font-size: 1vw;
 
}
.admin-table{
    width: 100%;
    font-weight: 400;
   
}

.admin-table table {
    border-collapse: collapse;
    width: 100%; /* Độ rộng của bảng */
}




.admin-table th {
    border-bottom: 1px solid #00000028; /* Đường viền dưới cho th và td */
    padding: 8px; /* Khoảng cách giữa nội dung và viền */
    text-align: center; 
    background-color: #00000028;
    border-radius: 4px;
}


.admin-table td {
   font-size: 0.8vw;
    padding:10px 8px; 
    text-align: center; 
}

.admin-table td button{
   margin: 0px 4px;
   padding: 4px 10px;
   color: white;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.admin-table td #button-xoa{
    background-color: #FC8019;
 }

 .admin-table td #button-sua{
    background-color: #F59E0B;
 }
 
 .admin-table td #button-xem{
  background-color: #4b4a49;
}



 .admin-table td #button-xoa:hover,
 .admin-table td #button-sua:hover,
 .admin-table td #button-xem:hover{
    opacity: 0.8;
 }
  

.admin-table img {
    width: 60px; /* Độ rộng tối đa của hình ảnh */
    height: auto; /* Chiều cao tự động điều chỉnh để giữ tỷ lệ khung hình */
    cursor: pointer;
    z-index: 1;
} 


.admin-content-component-top .admin-table img{
  width: 100px;
}

.admin-table span{
  margin: 5px 5px;
  cursor: pointer;
  font-weight: bold;
  line-height:2;
  color: #F59E0B;

}

.admin-table span i{
  vertical-align:middle ;
}

.admin-table span:hover{
 color: gray;
}


.admin-table span:hover{
 color: gray;
}

.admin-table .admin-size-checkbox {
  margin-right: 5px;
  border-radius: 10px;
  cursor: pointer;

  width: 30px;
  height: 30px;
  vertical-align: sub;
}

.admin-table #slsp {
 font-size: 0.8vw;
 padding: 10px 25px;
 border-radius: 4px;
 outline: none;
 border: none;
 background-color: rgba(76, 171, 206, 0.236);
}

.admin-table .color-size {
  width: 20px;
  height:20px;
  border-radius: 50%;
  top: 19px;
  position: relative;
 vertical-align: middle;
 }
 


.admin-size-label {
  margin-right: 12px;
  font-size: 1.2vw;
}

 



.sidebar-admin-form {
    position: fixed;
    top: 0;
    right: -640px; /* Điều chỉnh giá trị phù hợp với kích thước của sidebar */
    width: 600px; /* Điều chỉnh độ rộng của sidebar */
    height: 100%;
    background-color: white; /* Màu nền của sidebar */
    transition: right 0.3s ease; /* Hiệu ứng chuyển động */
    z-index: 9000;
    padding: 20px; /* Khoảng cách từ nội dung đến viền form */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .sidebar-admin-form.active {
    right: 0; /* Hiển thị sidebar khi có class active */
  }
  
  .admin-main-content.dark-overlay,
  .admin-sidebar.dark-overlay,
  #admin-header.dark-overlay,
  .admin-main-content img{
    background-color: rgba(111, 108, 108, 0.193); /* Màu nền mờ và độ trong suốt */
    z-index: 99999;
  
  }

  /* ListDanhMuc.css */

  .selected-image-container {
    position: fixed; /* Thiết lập vị trí cố định */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0000008e;
    z-index: 4000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .selected-image-container #selected-content{
    width: 37%;
    height: 90%;
    background-color: white;
    border-radius: 10px;    
    animation: slideIn 0.5s ease forwards;
  }

  .selected-image-container #selected-content .selected-image{
    width:100%;
    height: 100%;
    z-index: 3000;
    border-radius: 10px;
  }
  
  
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Điều chỉnh khoảng cách từ phân trang đến phần khác của giao diện */
  }
  .pagination-container button {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    cursor: pointer;
  }
  
  .pagination-container button:hover {
    background-color: #f0f0f0;
  }
  
  .admin-edit {
    position: fixed;
    top: 0%;
    left: 0%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.413);
    width: 200%;
    height: 200%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.282);
    z-index: 9999; /* Đảm bảo modal hiển thị trên các phần còn lại của trang */

    display: flex;
    align-items: center;
    justify-content: center;

  }
  

  .admin-edit .admin-edit-content{
    min-width: 18%;
    padding: 40px;
    min-height: 25vh;
    background-color: #ffffff;
    border-radius:7px;
    position: relative;
    top: 22%;
    left: 26%;
    animation: slideIn 0.5s ease forwards;
  }

  .admin-edit .admin-edit-content #close{
   position: absolute;
   right: 2%;
   top: 0;
   font-size: 1.5vw;
   cursor: pointer;
  }

  .admin-edit .admin-edit-content #close:hover{
   color: tomato;
   }

   @keyframes slideIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .admin-edit .admin-edit-content .form-admin-edit {
    margin-top: 20px;
  }

  .admin-edit .admin-edit-content .form-admin-edit h1 {
    font-size: 2vw;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
    color: white; /* Màu chữ */
    text-shadow: 2px 2px 4px #F59E0B; /* Đổ bóng chữ với màu đen */
 }
  .admin-edit .admin-edit-content .form-admin-edit label {
    margin-bottom: 4px;
    display: block;
  }
  
  .admin-edit .admin-edit-content .form-admin-edit input[type="text"],
  .admin-edit .admin-edit-content .form-admin-edit input[type="file"],
  .admin-edit .admin-edit-content .form-admin-edit select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Phù hợp với padding và border vào kích thước */
    outline: none;  
    background-color: rgba(128, 128, 128, 0.222);
    border: none;
  }
  
  .admin-edit .admin-edit-content .form-admin-edit input[type="text"]:focus,
  .admin-edit .admin-edit-content .form-admin-edit input[type="file"]:focus {
   background-color: white;
   border: 1px solid rgba(128, 128, 128, 0.457);
  }

  .admin-edit .admin-edit-content .form-admin-edit input[type="submit"] {
    background-color: #F59E0B;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-edit .admin-edit-content .form-admin-edit input[type="submit"]:hover {
    background-color: #f59f0b69;
    color: black;
  }


  .admin-edit #image-preview{
    width: 80px;
  }
  
 .admin-table .customer {
    color: blue; /* Màu văn bản cho khách hàng */
    font-size: 0.8vw;
  }
  
  .admin-table  .employee {
    color: green; /* Màu văn bản cho nhân viên */
    font-size: 0.8vw;
  }
  
  .admin-table  .admin {
    color: red; /* Màu văn bản cho admin */
    font-size: 0.8vw;
  }
  

  .admin-edit .admin-viewbill-content{
    min-width: 26%;
    padding: 40px;
    min-height: 40vh;
    background-color: #ffffff;
    border-radius:7px;
    position: relative;
    top: 23%;
    left: 26%;
    animation: slideIn 0.5s ease forwards;
  }

  .admin-edit .admin-viewbill-content #close{
    position: absolute;
    right: 2%;
    top: 0;
    font-size: 2vw;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
  }

  .admin-edit .admin-viewbill-content #close:hover{
    color: tomato;
  }
  .admin-edit .admin-viewbill-content #h1{
   font-size: 1.2vw;
   margin-bottom: 20px;
  }

  .admin-edit .admin-viewbill-content p{
    margin: 20px 0;
    font-size: 0.9vw;
    font-weight: bold;
  }

  .admin-edit .admin-viewbill-content p span{
    font-size: 0.9vw;
    font-weight: 400;
  }
  
  
  .admin-edit .admin-viewbill-content .viewbill-top{
   min-height: 30vh;
   
   width: 100%;
   display: flex;
  }

  .admin-edit .admin-viewbill-content .viewbill-top h1{
    font-size: 1vw;
    color: grey;
    font-weight: bold;
   }
  .viewbill-top .left{
    min-width: 50%;
   
  }

  .viewbill-top .left p,
  .viewbill-top .right p{
   color: grey;
   font-weight: bold;
  }

  .viewbill-top .right{
    width: 50%;
  }
  
  .admin-edit .admin-viewbill-content .viewbill-bottom{
   min-height: 30vh;
   width: 100%;
  }

  .admin-edit .admin-viewbill-content .viewbill-bottom h1{
    font-size: 1vw;
    font-weight: bold;
    color: grey;
    margin-bottom: 12px;
   }


  .admin-edit .admin-viewbill-content .viewbill-bottom table{
    width: 100%;

  }

  
  .admin-edit .admin-viewbill-content  #total{
    float: right;
    font-weight: bold;
    font-size: 1vw;
  }

  .admin-edit .admin-viewbill-content .viewbill-bottom table thead{
    background-color: #00000036;
    border-bottom: 1px solid black;
    color: rgba(255, 255, 255, 0.76);
    font-weight: bold;
  }

  .admin-edit .admin-viewbill-content .viewbill-bottom table tbody{
   text-align: center;
  }

  .admin-edit .admin-viewbill-content .viewbill-bottom table td{
      color: grey;
      font-weight: bold;
      font-size: 0.7vw;
   }

   .admin-edit .admin-viewbill-content .viewbill-bottom table th{
      padding: 5px 10px;
   }

   .admin-edit .admin-viewbill-content .viewbill-bottom table td,
   .admin-edit .admin-viewbill-content .viewbill-bottom table th{
     width: auto;
    }

    .admin-edit .admin-viewbill-content .viewbill-bottom table td{
      padding: 5px 0;
    }
      
     
 
  .admin-edit .admin-viewbill-content .viewbill-bottom table tbody #tl{
    text-align: left;
    width: 250px;
   }

   .viewbill-bottom table #tl .box-product{
    height: auto;
    width: 100%;
   
    display: flex;
   }

   .viewbill-bottom table #tl .box-product .left{
   width: 40%;
   height: 100px;
  
   }

   .viewbill-bottom table #tl .box-product .left .box-img{
    width: 80%;
    
  
    }
    .viewbill-bottom table #tl .box-product .left .box-img img{
      width: 100%;
      }
   .viewbill-bottom table #tl .box-product .right{
    width:60%;
   height: 100px;
    
    }

    .admin-edit .admin-viewbill-content .viewbill-bottom table tbody #tl p{
    margin-left: 5px;
     font-size: 0.7vw;
     color: grey;
     font-weight: bold;
     height: 5%;
     }

     .admin-edit .admin-viewbill-content .nav-button{
      width: 100%;
      height: auto;
    margin-top: 20px;

     }
     .admin-edit .admin-viewbill-content .nav-button #container-button{ 
    float: right;
     }
     
    .admin-viewbill-content .nav-button #container-button #default,
    .admin-viewbill-content .nav-button #container-button #primary{ 
      padding: 10px 14px;
      border-radius: 4px;
      border: none;
      color: white;
      font-weight: bold;
      font-size: 0.8vw;
      margin: 0 5px;
      cursor: pointer;
      transition: 0.2s;
       }
       
       .admin-viewbill-content .nav-button #container-button #primary{
        background-color: rgb(0, 110, 255);
       }

       .admin-viewbill-content .nav-button #container-button #primary:hover{
        background-color: rgba(91, 223, 166, 0.37);
        color: rgba(0, 0, 0, 0.442);
       }

       .admin-viewbill-content .nav-button #container-button #default{
        background-color: rgb(246, 61, 0);
       }

       .admin-viewbill-content .nav-button #container-button #default:hover{
        background-color: rgba(253, 2, 2, 0.37);
        color: rgba(0, 0, 0, 0.46);
       }


       .container-add-news {
        max-width: 95%;
        height: 100%;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      
      .container-add-news label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .container-add-news input[type="text"],
      .container-add-news textarea {
        width: 50%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box; /* Make sure padding and border are included in width */
      }
      
      .container-add-news input[type="submit"] {
        margin-top: 10px;
        background-color: #f57b09;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.4s;
        font-weight: bold;
      }

      .container-add-news input[type="submit"]:hover {
        border: 1px solid #f57b09;
        color:#f57b09 ;
        background-color: white;
        font-weight: bold;
      }
      
      /* Styling for ReactQuill */
      .quill-editor {
        width: 100%; /* Đặt chiều rộng theo ý muốn */
        height: 300px; /* Đặt chiều cao theo ý muốn */
      }
      
      .ql-editor {
        white-space: pre-wrap; /* Hiển thị đầy đủ nội dung */
      }
      
     

      table #new-an{
        margin:  auto;
        margin-top: 10px;
        text-align: center;
        width: 30%;
        padding: 2px 2px;
        border: 1px solid red;
        border-radius: 7px;
        color: red;
        font-weight: bold;
        font-size: 0.7vw;
        cursor: pointer;
        vertical-align: middle;
      }

      table #new-hien{
        margin:  auto;
        margin-top: 10px;
        text-align: center;
        width: 30%;
        padding: 5px 2px;
        border: 1px solid rgba(0, 255, 17, 0.711);
        border-radius: 7px;
        color:  rgba(0, 255, 17, 0.711);
        font-weight: bold;
        font-size: 0.7vw;
        cursor: pointer;
        vertical-align: middle;
      }

      table #new-hien i,
      table #new-an i{
       vertical-align: middle;
      }

      
      table #new-hien:hover,
      table #new-an:hover{
       opacity: 0.75;
      }

      .admin-size-label{
        font-size: 1vw;
        vertical-align: super;
      }

      