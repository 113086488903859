.user-container-header{
    position: fixed;
    background-color: #000000e0;
    height: 12vh;
    width: 100%;
    z-index: 1000;
    top: 0;

    transition: right 0.3s ease,top 0.3s;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.753);
}

.user-container-header a {
  text-decoration: none;
  color: white;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); /* Text shadow màu trắng */
}

#user-header{
    height: 100%;
    display: flex;
}

.user-header-top {
    display: flex; /* Sử dụng flexbox để các phần tử con nằm trên cùng một hàng */
    height: 2vh; 
    background-color: black;
    overflow: hidden; 
    align-items: center; 
}

.user-header-top p {
    color: white;
    font-size: 1vw;
    margin: 0 10%; /* Loại bỏ margin mặc định của các đoạn văn bản */
    white-space: nowrap; /* Ngăn chữ xuống dòng */
    overflow: hidden; /* Ẩn phần chữ vượt quá phạm vi của phần tử */
    animation: slideRight 2s linear infinite; /* Áp dụng hiệu ứng chạy từ trái sang phải */
}

.user-header-top p:nth-child(1) {
    animation-delay: 0s; /* Không có delay cho hContent 1 */
}
.user-header-top p:nth-child(2) {
    animation-delay: 1.5s; /* Delay 2s cho Content 2 */
}
.user-header-top p:nth-child(3) {
    animation-delay: 3s; /* Delay 4s cho Content 3 */
}
.user-header-top p:nth-child(4) {
    animation-delay: 4.5s; /* Delay 6s cho Content 4 */
}

@keyframes slideRight {
    0% {
        transform: translateX(200%);
    }
    100% {
        transform: translateX(-200%);
    }
}



.user-header-left{
    width: 18%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
   
}

.user-header-left .user-logo-header{
    width: 55%; 
    margin: 0 auto; 
    height: 80%;
    
}

.user-header-left .user-logo-header img{
  width: 100%;
 height: 100%;
 position: relative;
 margin-top: 2%;
}


.user-header-center{
    width: 62%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

#user-nav-menu{
    margin-left: 24%;
}
  
  .main-menu {
    display: flex;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-menu > li {
    margin: 30px 10px;
    font-weight: 500;
    cursor: pointer;
    font-size: 1.3vw;
    position: relative;
    right:50%;
  }
  

  
  #iccon-zoom-center{
    font-size: 1.4vw;
  }
  
  .main-menu li:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
  }
  
  .main-menu li:hover:after,
  .main-menu li.active:after {
    transform-origin: left;
    transform: scaleX(1);
  }
  
  .main-menu li.active {
    color: #000;
  }
  
  /* CSS for submenu (menu cấp 2) */
  .main-menu li.has-dropdown {
    position: relative;
   
  }

  .main-menu li.has-dropdown .material-icons {
    vertical-align: sub;
   
  }
  
  .main-menu li.has-dropdown ul.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    list-style: none;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    opacity: 0.79;
    z-index: 1;
    width: 200px;
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;
   
  }
  
  .main-menu li.has-dropdown ul.sub-menu  li{
    border-bottom:  0.1px solid white;
    padding: 20px 0px;
  }
  
  .main-menu li.has-dropdown:hover ul.sub-menu {
    display: block;
    visibility: visible;
  
    color: white;
    font-weight: bolder;
   
   
  }

  .main-menu li.has-dropdown:hover ul.sub-menu #link{
   color: white;
   
   
  }

  .main-menu li.has-dropdown:hover ul.sub-menu li{
    display: block;
    visibility: visible;
  
    color: white;
    font-weight: bolder;
   
   
  }

  .main-menu li.has-dropdown:hover ul.sub-menu #text{
   color: white;
   
   
  }
  
  .main-menu li.has-dropdown ul.sub-menu li {
    padding: 10px;
    font-size: 24px;
  
  }
  
  .main-menu li.has-dropdown ul.sub-menu li:hover {
   background-color: #0a0a0a;
    color: #595959;
  }
  
  /* CSS for sub-submenu (menu cấp 3) */
  .main-menu li.has-dropdown ul.sub-menu li.has-submenu {
    position: relative;
  }

  .main-menu li.has-dropdown ul.sub-menu li.has-submenu {
    position: relative;
  }
  
  
  .main-menu li.has-dropdown ul.sub-menu li.has-submenu ul.sub-sub-menu {
    position: absolute;
    top: 0;
    left: 100.5%;
    display: none;
    list-style: none;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    
    z-index: 1;
    width: 200px;
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;
  }
  
  .main-menu li.has-dropdown ul.sub-menu li.has-submenu:hover ul.sub-sub-menu {
    display: block;
    visibility: visible;
 
    
  }
  
  .main-menu li.has-dropdown ul.sub-menu li.has-submenu ul.sub-sub-menu li  {
    padding: 12.5px 10px;
   font-size: 1vw;
 
  
    text-decoration: none;
  }
  
  .main-menu li.has-dropdown ul.sub-menu li.has-submenu ul.sub-sub-menu li:hover {
    background-color: #0a0a0a;
    color: #ffffff;
    
  }


.user-header-right{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-header-right i{
 vertical-align: middle;
}

.user-menu li {
    display: inline-block;
    margin-right: 14px; /* Khoảng cách giữa các mục menu */
    /* vertical-align: middle; Căn giữa theo chiều dọc */
}

.user-menu li:last-child {
    margin-right: 0; /* Loại bỏ margin phải của mục menu cuối cùng */
}

.user-menu i{
  font-size: 1.4vw;
}

.user-menu i:hover{

  font-weight: bold;
}

/*  */

#spa{
  font-size: 0.8vw;
  vertical-align: text-top;
  position: relative;
  top:-1px;
  margin: 0 2px;
}
.user-menu li a {
    font-weight: 300; 
    display: inline-block; /* Hiển thị các biểu tượng trên cùng một dòng với chữ */
    font-size: 0.82vw; /* Căn chỉnh kích thước của biểu tượng */
    margin-right: 5px; /* Khoảng cách giữa biểu tượng và chữ */
    transition: all 0.2s ease-in-out;
  
}

.user-menu li a:hover {
   font-weight: bold;
}



#iccon-zoom-center{
    font-size: 1.4vw;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
}

#iccon-zoom-center:hover{
  font-size: 1.4vw;
  font-weight: bold;
  
}
/* CSS cho biểu tượng tìm kiếm */
#iccon-zoom-center {
    cursor: pointer;
}

#sidebar-find {
    position: fixed;
    top: 0;
    right: -26%; /* sidebar bắt đầu ẩn bên phải */
    width: 26%;
    height: 100%;
    background-color: #f1f1f1;
    transition: right 0.3s; /* Thêm hiệu ứng chuyển động */
    z-index: 1005;
}

#logo-find {
width:  20%;
position: absolute;
bottom: 5px;
right: 5px;
}

#logo-find img {
width: 100%;
  
  }
#closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2.6vw;
  font-weight: bold;
  cursor: pointer;
}

#closeButton:hover {
  color: tomato;
}

.header-cart-search,
.header-side-search{
    width: 70%;
    height: 50px;

    margin: 0 auto;
    margin-top: 80px;
    font-weight: bold;
    position: relative;
  
}

.header-cart-search p,
.header-side-search P{
  color:black;
  font-size: 0.9vw;
text-align: left;
  width: 70%;
}




.main-side-search {
  margin: 0 auto;
  width: 70%;
  height: 100vh;
}

.main-side-search .box-search{
  width: 100%;
  border-bottom: 2px solid rgba(128, 128, 128, 0.516);
}

.main-side-search input{
    padding: 20px;
    width: 100%;
    background:none;
    font-size:0.7vw;
    border: none;
    outline: none; /* Bỏ outline khi focus */
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.main-side-search input::placeholder {
  color: #999; /* Set the color of the placeholder text */
  font-size: 1vw; /* Adjust font size if needed */
}
.main-side-search input:focus {
    background-color: #779db9a4; /* Màu xanh nhạt khi focus */
    outline: none; /* Bỏ outline khi focus */
}

.main-side-search .box-search-result{
  width: 100%;
  max-height: 80vh;

}


.main-side-search .box-search-result table{
  width: 100%;
}


.main-side-search .box-search-result table td{
  padding: 10px 0;
}

.main-side-search .box-search-result #name_price-product{
  text-align: left;
  width: 70%;
 
}

.main-side-search .box-search-result #name_price-product span{
    font-size: 0.7vw;
    vertical-align: baseline;
    color: #272727;
    font-weight: 500;
}
.main-side-search .box-search-result #image-product{
  text-align: right;
  width: 15%;

}

.main-side-search .box-search-result .box-image-search{
  position: relative;
  right: 0;
  width: 100%;
  height: 7vh;
}
.main-side-search .box-search-result .box-image-search img{
  width: 100%;
  height: 100%;
}

#sidebar-cart {
  position: fixed;
  top: 0;
  right: -26%; /* sidebar bắt đầu ẩn bên phải */
  width: 26%;
  height: 100vh;
  background-color: #f1f1f1;
  transition: right 0.3s; /* Thêm hiệu ứng chuyển động */
  z-index: 1005;
}





 .clone-items-cart {
  margin: 0 auto;
  max-width: 65%;
  max-height: 20vh;
  
  overflow-x: auto; /* Add scrollbar for vertical overflow */
  overflow-y: auto; /* Add scrollbar for vertical overflow */
}


.clone-items-cart tbody{
  position: relative;
}


 .clone-items-cart tr #padding{
  padding: 10px 0;
}


 .clone-items-cart #col-image {
 width: 25%;
 height: 100%;
padding: 0 5px;
}

  .clone-items-cart #col-image img {
  width: 100%;


}

 .clone-items-cart #col-name {
  text-align: left;
 }
  .clone-items-cart #col-size-color {
  font-size: 0.6vw;
  color: grey;
  text-align: left;
 }
 .clone-items-cart #col-quanlity-price {
  font-size: 0.6vw;
  text-align: left;
 }
 .clone-items-cart #col-quanlity-price input {
  width: 16%;
  border: none;
  padding: 4px 2px;
  vertical-align: middle;
  outline: none;
  font-size: 0.7vw;
  text-align: center;
 }
 
  .clone-items-cart #col-quanlity-price span {
color: grey;
font-size: 0.6vw;
margin-left: 10px;
 }
 
.clone-items-cart #delete-cart {
  font-size: 0.7vw;
  cursor: pointer;
  transition: color 0.3s;
 position: absolute;
 top:20px;
 right: 5px;
 font-weight: bold;
   }

  .clone-items-cart #delete-cart:hover {
   color: tomato;
     }
  
   


.total-item-cart {
  margin: 20px auto;
   width: 70%;
  max-height: 138px;
  display: flex;
  border-top: 2px solid black;
  padding: 10px 0;
 
}


.total-item-cart  #left{
  width: 70%;
  font-size: 0.7vw;
  font-weight: bold;
}

.total-item-cart  #right{
  width: 30%;
  font-size: 0.7vw;
  font-weight: bold;
  text-align: right;
}

#sidebar-cart .container-button{
  margin: 20px auto;
   width: 70%;
   height: auto;
   display: flex;
   
}


#sidebar-cart .container-button #btn-left{
text-align: left;
 width: 47%;
 border: none;
 text-align: center;
 padding: 10px 0px;
 margin-right: 6%;
 background-color: black;
 color: white;
 font-weight: bold;
 transition: background-color 0.3s ease;
 cursor: pointer;
}


#sidebar-cart .container-button #btn-right{
  text-align: right;
  width: 47%;
 border: none;
  text-align: center;
  padding: 10px 0px;
  background-color: black;
  font-weight: bold;
  transition: background-color 1s ease;
  cursor: pointer;
}
#sidebar-cart .container-button #btn-right,
#sidebar-cart .container-button #btn-left{
  color: white;
  font-size: 0.7vw;
  border: none;
}


#sidebar-cart .container-button #btn-left:hover,
#sidebar-cart .container-button #btn-right:hover {
  background-color: white; /* Change background color on hover */
  background-image: linear-gradient(to right, white 50%, black 50%); /* Add gradient for animation effect */
  background-size: 200% 100%; /* Double the background size */
  transition: background-position 1s; /* Add transition for animation */
}

#sidebar-cart .container-button #btn-left:hover {
  background-position: -100% 0; /* Move gradient to the right on hover */
}

#sidebar-cart .container-button #btn-right:hover {
  background-position: -100% 0; /* Move gradient to the left on hover */
}
  
  



#user-box-cart{

  position: relative;
}


#user-box-cart{

  position: relative;
}

#user-box-cart #count-cart{
  width: 50px;
  position: absolute;
  top: -70%;
  right: -120%;
  padding: 6px 8px;
  font-weight: bold;
  border-radius: 100%;
  height: auto;
  font-size: 0.55vw;
  text-align: center;
}

@media only screen and (max-width: 1690px) {
  #user-box-cart #count-cart{
    top: -80%;
  }
}


@media only screen and (max-width: 1512px) {
  #user-box-cart #count-cart{
    top: -90%;
    right: -140%;
  }
}


@media only screen and (max-width: 1154px) {
  #user-box-cart #count-cart{
    top: -90%;
    right: -170%;
  }
}

@media only screen and (max-width: 1078px) {
  #user-box-cart #count-cart{
    top: -100%;
   
  }
}

#mb-user-header{
  display: none;
}

.app{
  display: none;
}

@media only screen and (max-width: 1349px) {
 #user-header{
  display: none;
 }




 .user-container-header{
    height: 10vh;
}

 #mb-user-header{
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  height: 10vh;
 }

 #mb-user-header .left{
 width: 30%;
 height: 100%;
 display: flex;
 align-items: center;

 }

 #mb-user-header .left i{
font-weight: bold;
font-size: 5vw;
margin-left: 20px;
color: white;
  }
 

 #mb-user-header .center{
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  #mb-user-header .center a{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    #mb-user-header .center img{
      width: 42%;
      
      }
  #mb-user-header .right{
    width: 30%;
    height: 10vh;
    display: flex;
    justify-content: right;
    align-items: center;
    }

    #mb-user-header ul{
    display: inline-flex;
    margin-right: 20px;
      }
    #mb-user-header li{
      margin-left: 14px;
      position: relative;
      }

      #mb-user-header .right #user-box-cart i{
       font-size: 3vw;
  
        }
        #mb-user-header .right #user-box-cart #count-cart{
          top: -64%;
          right: -100%;
          font-size: 1vw;
           }

           .app {
            position: relative;
            display: block;
          }
          
           .menu-icon {
            cursor: pointer; /* Để con trỏ chuột thay đổi khi di chuột qua */
          }
          
          .app .sidebar {
            position: fixed; /* Giữ sidebar cố định khi cuộn trang */
            top: 0;
            left: -80%; /* Đặt sidebar ra ngoài màn hình ở bên trái */
            width: 80%; /* Chiều rộng của sidebar */
            height: 100%; /* Chiều cao đầy đủ */
            background-color: #0e0d0dbd; /* Màu nền của sidebar */
            transition: left 0.3s; /* Tạo hiệu ứng chuyển động */
            z-index: 4000;
          }
          
          .app .sidebar.open {
            left: 0; /* Di chuyển sidebar vào màn hình */
          }

          .close-icon{
            position: absolute;
            right: 0;
            font-weight: bold;
            top: 0.5%;
            color: white;
          }
          
          
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  max-height: 95vh;
  overflow-y: auto; 
}

.menu-item {
  position: relative;
  cursor: pointer;
  padding: 10px;
  color: white;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.3s ease; 
  font-size: 4vw;
}
.menu-list i{
  position: relative;
  top: -4px;
  font-size: 5vw;
  font-weight: bold;
 }

.menu-item:hover {
  background-color: #3d3d3d9c;
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out; 
  color: white;

}


.submenu.open {
  max-height: 500px; /* Adjust the maximum height as needed */
  
}

.submenu .menu-item {
  padding-left: 20px; /* Adjust the indentation for submenu items */
  
}

.sub-submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  
}

.sub-submenu.open {
  max-height: 500px; /* Adjust the maximum height as needed */
}

.sub-submenu .menu-item {
  padding-left: 40px; /* Adjust the indentation for sub-submenu items */
}

.sub-submenu li {
  list-style: none;
  padding: 8px;
  margin-left: 20px;
  transition: background-color 0.3s ease; 
  font-size: 3.5vw;
  color: white;
}

.sub-submenu li:hover {
  background-color: #919191a9;
}
  
}


@media only screen and (max-width: 654px) {
  #mb-user-header .left i{
    font-size: 7vw;
      }
      #mb-user-header .center img{
        width: 60%;
        }

        #mb-user-header .right #user-box-cart i{
          font-size: 5vw;
     
           }
           #mb-user-header .right #user-box-cart #count-cart{
             top: -64%;
             right: -100%;
             font-size: 2vw;
              }

          #mb-user-header .right li{
            margin-left: 10px;
          }
          #mb-user-header .right li i{
          font-size: 3.5vw;
          }
}

@media only screen and (max-width: 490px) {
  #mb-user-header .left i{
    font-size: 8vw;
      }

      #mb-user-header .center img{
        width: 70%;
        }

        #mb-user-header .right #user-box-cart i{
          font-size: 7vw;
     
           }
           #mb-user-header .right #user-box-cart #count-cart{
             top: -50%;
             right: -80%;
             font-size: 2vw;
              }
              #mb-user-header .right li i{
                font-size: 4vw;
                }
}

@media only screen and (max-width: 390px) {
  #mb-user-header .left i{
    font-size: 8vw;
      }

      #mb-user-header .center img{
        width: 70%;
        }

        #mb-user-header .right #user-box-cart i{
          font-size: 7vw;
     
           }
           #mb-user-header .right #user-box-cart #count-cart{
             top: -60%;
             right: -90%;
             font-size: 2vw;
              }
}

/* App.css */

