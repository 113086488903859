/* Reset CSS */


.container-gioithieu {
    max-width: 80%; /* Không vượt quá 80% chiều rộng */
  
}

/* main {
    padding: 20px 0;
} */

.container-gioithieu .banner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 500px; /* Điều chỉnh chiều cao cố định */
    clear: both;
    margin-bottom: 20px;
    margin-top: 20px;
}



.container-gioithieu .banner img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    object-fit: cover; /* Đảm bảo ảnh luôn được hiển thị mà không bị vặn hoặc căng */}

    .introduce {
        display: flex;
        flex-direction: column; /* Sắp xếp theo chiều dọc */
        justify-content: center;
        align-items: center;
    }
    

.introduce h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2vw;
}

.introduce h2 {
    margin-bottom: 20px;
    font-size: 1.5vw;
}

.introduce p {
    margin-bottom: 20px;
    font-size: 1.5vw;
}

.introduce b,
.introduce i {
    font-size: 1.1vw;
}


.introduce .image-1 img {
    width: 33%;
    height: auto;
    clear: both;
    margin-top: 20px;
    margin-bottom: 20px;
}



.introduce .image-2 {
    display: flex;
    flex-direction: row; /* Sắp xếp theo chiều ngang */
    justify-content: center;
    align-items: center;
}
.introduce .image-2 img {
    width: 30%;
    height: auto;
    clear: both;
}

/* Các điểm ngắt thông dụng */
@media (max-width: 1200px) {
    .introduce h2 {
     font-size: 4vw;
    }
    .container-gioithieu {
        width: 90%; /* Thay đổi chiều rộng khi màn hình nhỏ hơn */
    }

    .introduce .image-1 img {
        width: 50%; /* Điều chỉnh kích thước hình ảnh */
    }

    .introduce .image-2 img {
        width: 40%; /* Điều chỉnh kích thước hình ảnh */
    }
}

@media (max-width: 768px) {
    .introduce h1 {
        font-size: 4vw;
       }
    .container-gioithieu {
        width: 100%; /* Chiếm toàn bộ chiều rộng */
    }

    .introduce .image-1 img {
        width: 100%; /* Hình ảnh chiếm toàn bộ chiều rộng */
    }

    .introduce .image-2 {
        flex-direction: column; /* Xếp theo chiều dọc */
    }

    .introduce .image-2 img {
        width: 70%; /* Điều chỉnh kích thước hình ảnh */
    }
    .introduce p {
        margin-bottom: 20px;
        font-size: 3vw;
    }
}
