.container-baiviet .content{
    width: 75%;
    margin: 20px auto;
}
.container-baiviet .content > h1{
    font-size: 1.4vw;
    text-align: center;
}
.container-baiviet .box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.container-baiviet .box-0{
    margin: 20px 0;
    width: 32%;
    text-align: center;
}
.container-baiviet .img{
    width: 100%;
    height: 400px;
}
.container-baiviet .img img{
    width: 100%;
    height: 100%;
}
.container-baiviet .tieude,.thoigian,.noidung{
    margin-top: 15px;
}
.container-baiviet .tieude{
    font-size: 27px;
    font-weight: 400;
    text-transform: uppercase;
}
.container-baiviet .thoigian{
    font-size: 18px;
}
.container-baiviet .noidung{
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;

}
.container-baiviet button{
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid;
    background-color: white;
    cursor: pointer;
}
.container-baiviet button:hover{
    border: 1px solid rgb(53, 52, 52);
    color: rgb(53, 52, 52);
}

@media (max-width:1300px) {
    .container-baiviet   .content{
        width: 80%;
    }
    .container-baiviet .box-0{
        width: 48%;
    }
    .container-baiviet  .img{
        height: 500px;
    }
}

@media (max-width: 1000px) {
    .container-baiviet .content{
        width: 60%;
    }
    .container-baiviet .box-0{
        width: 100%;
    }
   
}
@media (max-width: 800px) {
    .container-baiviet .content{
        width: 75%;
    }
   
}
@media (max-width: 600px){
    .container-baiviet .content{
        width: 80%;
    }
    .container-baiviet .img{
        height: 400px;
    }

    .container-baiviet button{
       font-size: 2vw;
    }
}