.container-cart-user  {
    border: none;
    min-height: 200px;
}

.container-cart-user .box {
    width: 75%;
    margin: 50px auto;
}

.container-cart-user  h2 {
    text-transform: uppercase;
}

.container-cart-user table{
    border-collapse: collapse;
}
.container-cart-user  th{
    border-bottom: 1px solid rgba(128, 128, 128, 0.797);
    
}

.container-cart-user  th {
    font-weight: 300;
    padding: 10px;
}

.container-cart-user  .table {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.container-cart-user  .table-left {
    width: 75%;
}

.container-cart-user  .td-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    
}

.container-cart-user  .td-1 .img {
    width: 26%;
    height: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20PX;
    object-fit: cover;
    background-size: cover;
}

.container-cart-user  .img img {
    width: 100%;
   
}

.container-cart-user  .td-1 .information {
    width: 50%;
    padding: 40px 0px;
}

.container-cart-user  .td-2 {
    width: 25%;
    
}

.container-cart-user  .td-2 input {
   text-align: center;
   width: 22%;
}


.container-cart-user  .td-2 .amount {
    display: flex;
    justify-content: center;
}

.container-cart-user  .td-2 span {
    padding: 10px;
    border: 1px solid #eee;
    cursor: pointer;
}

.container-cart-user  .td-3 {
    width: 25%;
    text-align: center;
}

.container-cart-user  .td-4 {
    width: 10%;
    text-align: center;
}

.container-cart-user  .td-4 i:hover {
    color: red;
    cursor: pointer;
}

.container-cart-user  .table-right {
    width: 24%;
    height: 100px;
    border: none;
}

.container-cart-user  .total {
    display: flex;
    justify-content: space-between;
    padding: 32px 25px;
    border: 1px solid #0000001a;
}

.container-cart-user  .pay {
    border: 1px solid #0000001a;
}

.container-cart-user  .payment {
    margin: 25px auto;
    text-align: center;
    border-radius: 25px;
    padding: 15px;
    width: 200px;
    max-width: 100%;
    background-color: black;
    color: white;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.container-cart-user  .payment:hover {
    color: black;
    background-color: white;
    border: 1px solid;
    cursor: pointer;
}

.container-cart-user  .continue {
    background-color: black;
    color: white;
    padding: 15px 30px;
    border-radius: 20px;
    margin-top: 20px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    font-size: 0.8vw;
}

.container-cart-user  .continue:hover {
    color: black;
    background-color: white;
    border: 1px solid;
    cursor: pointer;
}

@media (min-width:768px) and (max-width:1024px) {
    .container-cart-user  .box {
        width: 95%;
    }

    .container-cart-user  .img img {
        width: 90%;
        height: 100%;
    }
    .continue{
        display: none;
    }

    
}

@media (max-width: 767px) {

    
.container-cart-user  th {
    font-size: 2vw;
}
    .container-cart-user   .box {
        width: 80%;
    }

    .container-cart-user  .table {
        flex-direction: column;
    }

    .container-cart-user  .table-left {
        width: 100%;
    }

    .container-cart-user  .td-1 {
        flex-direction: column;
    }

    .container-cart-user   .td-1 .img {
        width: 100%;
    }

    .container-cart-user   .td-1 img {
        width: 70%;
        height: 100%;
    }

    .container-cart-user .td-1 .information {
        width: 100%;
        padding: 0px 20px 10px;
    }

    .container-cart-user  .table-right {
        margin-top: 20px;
        width: 100%;
    }

    .container-cart-user  .continue {
        display: none;
    }

    .container-cart-user  .payment {
        font-size: 2.5vw;
       
     }
     .amount span{
       font-size: 2vw;
     }
     .amount input{
        font-size: 2vw;
        font-weight: bold;
        border: none;
     }

     .td-3{
        width:20% ;
        font-size: 2.2vw;
     }

     .container-cart-user  .total  span{
       font-size: 3vw;
    }

    .information-name{
        font-size: 2vw;
    }
    .information-size{
        font-size: 2vw;
    }

    .information-price{
        font-size: 2vw;
    }
}

