
.user-form-dangky {
    width: 50%;
    margin:20px auto;
    padding-bottom: 20px;
}

.user-form-dangky h3 {
    font-weight: 700;
    color: black;
}

.user-form-dangky input[type="radio"]{
    width: 20px;
    height: 20px;
  vertical-align: top;
}

.user-form-dangky label{
    font-size: 1vw;
    vertical-align: middle;
}
.user-form-dangky input[type="text"],
.user-form-dangky input[type="password"]{
    display: block;
    margin: 25px 0;
    padding: 20px;
    width: 100%;
    background-color: #ededed;
    border: none;
    border-radius: 10px;
    outline: ivory; 
    transition: 0.4s;
}

.user-form-dangky select{
    display: block;
    margin: 25px 0;
    padding: 20px;
    width: 100%;
    background-color: #ededed;
    border: none;
    border-radius: 10px;
    outline: ivory; 
    transition: 0.4s;
}
.user-form-dangky input[type="text"]:focus  {
    background-color: #ededed41;
    border: 0.5px solid rgba(128, 128, 128, 0.599);
    outline: none; 
}
input[type="radio"]:checked {
    accent-color: black;
}

.user-form-dangky label {
    color: black;
    margin-right: 10px;
}

.user-form-dangky button {
    padding: 15px 25px;
    background-color: black;
    color: white;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 0 0 0 white; /* Thêm box-shadow với màu trắng và độ blur là 0 */
}

.user-form-dangky button:hover {
    cursor: pointer;
    color: black;
    background-color: white;
    border: 1px solid;
    box-shadow: 0 0 20px 10px white;
}

.user-form-dangky .return {
    margin-top: 20px;
    color: black;
}

.user-form-dangky .return:hover {
    text-decoration: underline;
    cursor: pointer;
}

.user-form-dangky .return i {
    margin-right: 20px;
    vertical-align: sub;
}

@media (max-width: 957px) {
    .user-form-dangky {
        width: 80%;
    }
    .user-form-dangky button {
    width: 100%;
    font-size: 2vw;
    }
    .user-form-dangky input[type="text"],
    .user-form-dangky input[type="password"]{
    font-size: 2vw;

}

.user-form-dangky h3 {
    font-size: 3vw;
}

.user-form-dangky input[type="radio"]{
    margin-bottom: 20px;
    vertical-align: top;
}

.user-form-dangky label{
    font-size: 3vw;
    vertical-align: top;
   
}
.user-form-dangky .return i {
    margin-right: 20px;
    vertical-align: sub;
    font-size: 8vw;
}
.user-form-dangky .return sup {
    margin-right: 20px;
    vertical-align: sup;
    font-size: 4vw;
    position: relative;
    top: 40%;
}
}

@media (max-width: 739px) {
    .user-form-dangky {
        width: 80%;
    }

    .user-form-dangky button {
        width: 100%;
    }

 
}