
.container-lienhe i{
    vertical-align: middle;
    margin-right: 10px;
}
.container-lienhe .content {
    width: 80%;
    margin: 30px auto;
}
.container-lienhe .content-1{
    width: 70%;
    margin: 100px  auto;
    
}

.container-lienhe .content-1 h2 {
    text-align: center;
    font-size: 2vw;
    margin-bottom: 20px;
}
.container-lienhe iframe{
    width: 100%;
    height: 100%;
    border: none;
}

.container-lienhe .box {
    
    display: flex;
    justify-content: space-between;
    height: 350px;
}

.container-lienhe .box-left{
    width: 56%;
}

.container-lienhe .box-right {
    width: 40%;
}
.container-lienhe .box-right-0{
    font-weight: 600;
    margin-bottom: 20px;
}
.container-lienhe .box-right-0 p{
    font-weight: 400;
}
.container-lienhe .box-right-0 p a{
    color: black;
}


.container-lienhe .content-2 {
    margin: 0px auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.998);
    padding: 30px 0;
}

.container-lienhe .content-2>h2 {
    text-align: center;
  
    margin: 20px 0;
    color: white;
    font-size: 1.6vw;
}

.container-lienhe form{
    width: 40%;
    margin: 0 auto;

}
.container-lienhe input,
.container-lienhe textarea {
    padding: 17px 20px;
    border-radius: 4px;
    margin: 5px 0;
    color: black;
    font-weight: bold;
    width: 100%;
    outline: none;
    font-size: 0.9vw;
    background-color: rgba(255, 255, 255, 0.915);
}

.container-lienhe button {
    padding: 17px 15px;
    background-color: rgb(0, 0, 0);
    border:  1px solid white;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}
@media (max-width:1024px){
    .container-lienhe  .content-1{
        width: 100%;
    }
}
@media (max-width: 767px){
    .container-lienhe  .box{
        flex-direction: column;
        height: auto;
    }
    .container-lienhe .content-2{
        width: 100%;
    }
    .container-lienhe input,textarea{
        width: 100%;
    }
    .container-lienhe .box-left{
        width: 100%;
        height: 500px;
    }
    .container-lienhe .box-right{
        margin-top: 40px;
        width: 100%;
        font-size: 3vw;
    }
    .container-lienhe .box-right-0{
        font-size: 4vw;
    }

    .container-lienhe .box-right-0 a,
    .container-lienhe .box-right-0 p {
        font-size: 3vw;
    }

    .container-lienhe form{
        width: 60%;
        margin: 0 auto;
    
    }

    .container-lienhe .content-2  h2{
        font-size: 4vw;
    
    }

    .container-lienhe input,
.container-lienhe textarea {
 font-size: 3vw;
}
.container-lienhe button {
font-size: 3.5vw;
}
}


@media (max-width: 600px){
    .container-lienhe .box-right{
       font-size: 3vw;
    }

    .container-lienhe .content-2{
        width: 100%;
    }

    .container-lienhe form{
        width: 80%;
        margin: 0 auto;
    
    }
    .container-lienhe input,
    .container-lienhe textarea {
     font-size: 3vw;
    }
}