/* Add these styles to your CSS file */
.background-Product {
    position: relative;
    top: -28px;
    left: 0;
    width: 100%;
    height:51vh;

  
  }
  
  .background-Product video {
    object-fit: cover;
    width: 100%; /* Điều chỉnh kích thước của thuộc tính cover tại đây */
    height: 100%; /* Điều chỉnh kích thước của thuộc tính cover tại đây */
    filter: brightness(70%); /* Điều chỉnh độ sáng nếu cần */
    transition: filter 0.5s ease; /* Thêm hiệu ứng chuyển động mượt mà */
    pointer-events: none; /* Ngăn nhấp chuột */
    -webkit-touch-callout: none; /* Ngăn chặn hành vi nhấp chuột trên iOS */
    -webkit-user-select: none; /* Ngăn nhấp chuột vào các phần tử được chọn */
    -moz-user-select: none; /* Tương tự cho Firefox */
    user-select: none; /* Ngăn các thao tác nhấp chuột */
  }
  
  /* Thêm hiệu ứng hover để thay đổi độ sáng khi di chuột qua (tùy chọn) */
  .background-Product:hover video {
    filter: brightness(100%);
  }
  

  .nav-product{
    width: 100%;
    height: auto;
   
    
}

.nav-product .Container-item-nav{
   width: 90%;
    min-height: 50px;
   border-bottom:  1px solid black;  
   margin: 0 auto;
   
}


.nav-product .item-nav-left{
  float: left;
  width: 60%;
}

.nav-top-product {
  width: 84%;
  margin: 0 auto;
  height: auto;

  display: flex;
 
  align-items: center;
}

.nav-top-product .choose{
max-height: 70px;
min-width: 100px;

margin: 0 10px;
}

.nav-top-product .choose .choose-top{
 width: 100%;
 height: 50%;
font-weight: bold;
 color: rgba(0, 76, 241, 0.938);
 
 display: flex;
 align-items: center;
  }

  .nav-top-product .choose .choose-top span{
 font-size: 0.7vw;
 font-weight: bold;

     }

     .nav-top-product .choose .choose-bottom{
      margin-top:2px;
    width: 100%;
    height: 50%;

    font-size: 0.6vw;
    padding: 10px 20px;
    font-weight: 300;
    border-radius: 4px;
    text-align: center;
    color: white;
       }

       
.item-nav-left #priceFilter,
.item-nav-left #loaiFilter,
.item-nav-left #colorFilter,
.item-nav-right #thutuFilter{
    width: 12%;
    font-size: 16px;
    padding: 8px;
    border:none;
    background:none;
    cursor: pointer;
    font-weight: bold;
    margin: 0 5px;
    outline: none;
    margin-top: 5px;
}



.item-nav-left #priceFilter{
    width: 25%;

}




.item-nav-left #colorFilter{
    width: 14%;
}

.item-nav-right #thutuFilter{
    width: 100%;
    border: 1px solid black;
    
}

.item-nav-left #priceFilter option,
.item-nav-left #loaiFilter option,
.item-nav-left #colorFilter option{
  font-size: inherit; /* Kế thừa kích thước chữ từ thẻ select */
  font-weight: inherit; /* Kế thừa độ đậm từ thẻ select */
  font-variant: inherit; /* Kế thừa dạng chữ từ thẻ select */
  min-width: inherit; /* Kế thừa độ rộng tối thiểu từ thẻ select */
}

#priceFilter option:hover,
#loaiFilter option:hover,
#loaiFilter option:hover{
    background-color: #f0f0f0;
}

#priceFilter option:checked,
#loaiFilter option:checked,
#loaiFilter option:checked{
    background-color: #e6e6e6;
    font-weight: bold;
}


/* Optional: Style for the container or surrounding area */
/* Replace '.container' with the actual class or ID of your container */

.nav-product .item-nav-right{
   float: right;
   width: 10%;
   margin-right: 5px;
}


@media only screen and (max-width: 1452px){
  .item-nav-left #priceFilter,
  .item-nav-left #loaiFilter,
  .item-nav-left #colorFilter
 {
      width: 20%;
      font-size: 12px;

  }

  .item-nav-right #thutuFilter{
    font-size: 12px;
  }

  #view-more-product button{
    width: 20%;
    height: 40px;
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 946px){
  .item-nav-left #priceFilter,
  .item-nav-left #loaiFilter,
  .item-nav-left #colorFilter
 {
      width: 22%;
      font-size: 12px;

  }

  .item-nav-right #thutuFilter{
    font-size: 12px;
    width: 120%;
  
  }
}


@media only screen and (max-width: 762px){
  .item-nav-left #priceFilter,
  .item-nav-left #loaiFilter,
  .item-nav-left #colorFilter
 {
      width: 25%;
      font-size: 1.5vw;

  }

  .item-nav-right #thutuFilter{
    font-size: 1.5vw;
    width: 130%;
  
  }
  .nav-top-product .choose .choose-top span{
    font-size: 1.2vw;
   
   
        }
   
        .nav-top-product .choose .choose-bottom{
      
       font-size: 0.9vw;
      
          }
   
}


@media only screen and (max-width: 600px){
  .item-nav-left #priceFilter,
  .item-nav-left #loaiFilter,
  .item-nav-left #colorFilter
 {
      width: 100%;
      font-size: 2vw;

  }


  .item-nav-right #thutuFilter{
    font-size: 1.5vw;
    width: 150%;
  
  }

  .nav-product .Container-item-nav{
   margin: 20px 0;
   border: none;
    
 }
 .nav-top-product .choose .choose-top span{
  font-size: 2vw;
 
 
      }
 
      .nav-top-product .choose .choose-bottom{
    
     font-size: 1.2vw;
    
        }
}


#view-more-product{
  width: 100%;
  display: flex;
  justify-content: center;
}

#view-more-product button{
  margin: 0 auto;
  cursor: pointer;
  padding: 4px 25px;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

#view-more-product button:hover{
 border: 1px solid black;
 background-color: white;
 color: black;
}