
.container-bill-succes{
   
}
.container-bill-succes>p {
    color: black;
    text-align: center;
    margin-bottom: 20px;
    
}

.container-bill-succes h2 {
    text-transform: uppercase;
    text-align: center;
    margin: 15px;
    font-weight: 1000;
    font-size: 2vw;
    color: rgba(0, 128, 0, 0.595);
}

.container-bill-succes .img {
    display: flex;
    justify-content: center;
}

.container-bill-succes img {
    margin-top: 10px;
}

.container-bill-succes .content {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.container-bill-succes .content-left,
.container-bill-succes .content-right {
    width: 30%;
    height: auto;
    border: 1px solid gray;
    margin: 10px;
    border-radius: 8px;
}

.container-bill-succes .content-left {
    min-height: 180px;
    position: relative;
    border-bottom: none;
}

.container-bill-succes .content-left button {
   position: absolute;
   bottom: -50px;
    border-radius: 4px;
}

.container-bill-succes .content-left>p,
.container-bill-succes .content-right>p {
    background-color: rgb(31, 31, 31);
    padding: 20px;
    color: white;
    text-transform: uppercase;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.container-bill-succes .content-left-box-1 {
    padding: 5px;

   
}

.container-bill-succes .content-left-box-1 p {
    margin: 18px 3px;
    font-weight: bold;
}

.container-bill-succes .content-left-box-1 p span {
    font-weight: 300;
}

.container-bill-succes .content-left button {
    
    color: white;
    font-weight: bold;
    border: 1px solid rgb(55, 54, 54);
    
}

.container-bill-succes .content-right {
    position: relative;
}

.container-bill-succes .thongtinsanpham {
    padding: 10px 10px 10px 10px;
    display: flex;
    position: relative;
}

.container-bill-succes img {
    width: 70px;
}

.container-bill-succes .tensanphamsoluong {
    padding: 20px;
}

.container-bill-succes .giatien {
    position: absolute;
    top: 30px;
    right: 10px;
}

.container-bill-succes .thanhtien {
    padding: 10px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-radius: 8px;
}

.container-bill-succes .thanhtien span {
    float: right;
}

.container-bill-succes .chiphigiaohang {
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
}

.container-bill-succes .giaohangngay {
    padding: 0 10px 20px;
}

.container-bill-succes .giaohangngay span {
    float: right;
}

.container-bill-succes .tong {
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-radius: 8px;
}

.container-bill-succes .tong span {
    float: right;
    font-weight: 300;
    text-transform: lowercase;
}

.container-bill-succes .box-3 {
    padding: 5px;
}

.container-bill-succes .phuongthucthanhtoan {
    padding: 5px;
    font-weight: 600;
}

.container-bill-succes .chuyenkhoanquanganhang {
    padding: 5px;
}

.container-bill-succes button {
    position: absolute;
    padding: 10px;
    width: 100%;

    cursor: pointer;
  
    padding: 20px 0;
    color: white;
    font-weight: bold;
    background-color: rgb(0, 0, 0);
}

button:hover {
    background-color: black;
    color: white;
}

@media (max-width:1024px) {
    .container-bill-succes .content {
        flex-direction: column;
        padding-bottom: 60px;
    }

    .container-bill-succes .content-left {
        width: 50%;
        margin: 0 auto;
    }

    .container-bill-succes .content-left button {
        margin-top: 10px;
    }

    .container-bill-succes .content-right {
        width: 50%;
        margin: 70px auto;
    }
}

@media (max-width: 767px) {

    .container-bill-succes .content-left,
    .container-bill-succes .content-right {
        width: 70%;
    }
}