*{
    padding: 0px;
    margin: 0px;
}

#admin-body{
    width: 100%;
    background-color: #D9D9D9;
    max-height: 100vh;
    display: block;
}

#admin-header{
    height: 10vh;
    display: flex;
    position: fixed;
    background-color: #D9D9D9;
    z-index: 1000;
}

#admin-header .admin-header-left{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#admin-header .admin-header-left .admin-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94%;
    height: 100%;
}

#admin-header .admin-header-left .admin-logo img{
    width: 84%;
}

#admin-header .admin-header-center{
    width: 70%;
    display: flex;
}

#admin-header .admin-header-center .admin-center-left{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.admin-center-left .admin-search{
    background-color: #f4f4f4;
    box-shadow: 0.5px 2px 2px black;
    width: 60%;
    height: 40%;
    position: relative;
    right: 10%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.admin-center-left .admin-search input{
    width: 90%;
    height: 100%;
    background: none;
    font-size: 0.9vw;
    border: none;
    font-weight: 400;
}

.admin-center-left .admin-search input:focus {
    outline: none;
}

.admin-center-left .admin-search button{
    background: none;
    border: none;
    cursor: pointer;
  
}

.admin-center-left .admin-search button i{
   
    font-size: 1.5vw;
}

#admin-header .admin-header-center .admin-center-right{
    width: 30%;
   
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-center-right .box-date-time{
    width: 80%;
    height: 40%;
    font-size: 1.3vw;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

 #admin-header .admin-header-right{
    display: flex;
    align-items: center;
    justify-content: center;
    width:10%;
}

.admin-header-right .admin-box-user{
    padding:1.4% 4%;
    border-radius: 8px;
    height: 50%;
    width: 60%;
    background-color: #615656;

    display: flex;
    align-items: center;
    justify-content: center;
}

.box-user-left{
    width: 40%;
    height: 100%;
}

.box-user-left i{
    font-size: 1.8vw;
    padding: 2px;
    color: rgb(80, 67, 67);
    border-radius: 50%;
    background-color: black;
    padding: 2px;
}

.box-user-right{
    width: 70%;
    height: 100%;
    color: white;
    font-weight: bold;
    font-size: 0.8vw;
}

.box-user-left,
.box-user-right{
    display: flex;
    align-items: center;
    justify-content: center;
}


.admin-container{
    width: 100%;
    height: 100%;
    background-color: #D9D9D9;
    display: flex;
   
}

.admin-container .admin-sidebar{
    position: fixed;
    width: 17%;
    height: 89.5vh;
    margin-top:10vh;
    background-color: #F4F2EE;
    border-top-left-radius: 100px;
}


.admin-container-sidebar{
    margin-top: 10vh;
    height: 90vh;
    width: 100%;
}

.admin-sidebar-top{
    width: 100%;
    height: 88%;
    text-align: center;
    position: relative;
}

.admin-sidebar-top h1 {
    position: relative;
    top: -6%;
    left: 5%;
    font-weight: 200;
   padding: 2% 7%;
   background-color: #fc7f1972;
   color: #FC8019;
   border-radius: 4px;
   display: inline-block;
   box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.367); /* Đổ bóng */
   border-top-left-radius: 6px;
   border-top-left-radius: 6px;
   font-size: 1.8vw;
   
 }

 .admin-sidebar-top h1 i {
    margin-right: 5px;
    font-size: 2vw;
    vertical-align: middle;
 }
.vertical-menu {
    top: -15px;
    position: relative;
    width: 100%; /* Độ rộng của menu */
    height: 100%; /* Chiều cao tự động */
   
}
.vertical-menu #a {
    color: #495057; /* Màu chữ */
    border-bottom: 2px solid white;
    display: flex;
    font-size: 1.2vw;
    padding: 12px;
    text-decoration: none;
    
}
.vertical-menu #a i {
    margin-right: 15px; /* Khoảng cách giữa biểu tượng và văn bản */
}
.vertical-menu #a:hover {
    background-color: #dee2e6; /* Màu nền khi hover */
}

.vertical-menu #a.active {
    background-color: #dee2e6; /* Màu nền khi active */
}

.admin-sidebar-bottom{
    width: 100%;
    height: 12%;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    bottom: 0;
}


.admin-sidebar-bottom a{
    color: black;
    text-decoration: none;
    font-size: 1vw;
 }

 .admin-sidebar-bottom a:hover{
    color: gray;
 }

.admin-sidebar-bottom a i{
   margin-right: 15px;
   margin-bottom: 10px;
   vertical-align: middle;
}

.admin-sidebar-bottom a span {
    vertical-align: middle; /* Canh chỉnh theo chiều dọc */
 }


.admin-container .admin-main{
    margin-left: 17%;
    width: 83%;
    min-height: 90vh;
    background-color: rgba(0, 0, 0, 0.628);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
}

.admin-main .admin-main-content{
    width: 98.8%;
    min-height: 88vh;
    border-radius: 16px;
    background-color: white;
    
}

