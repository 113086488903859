#main{
    min-height: 100vh;
    padding: 30px 0px;
    background-color: white;
    width: 100%;
    position: relative;
    transition: 0.3s right ;
    margin-top: 11vh;
}

@keyframes slideInRight {
    0% {
    transform: translateX(100%);
    opacity: 0;
    }
    100% {
    transform: translateX(0);
    opacity: 1;
    }
}

@keyframes slideOutLeft {
    0% {
    transform: translateX(0);
    opacity: 1;
    }
    100% {
    transform: translateX(-100%);
    opacity: 0;
    }
}

#container-main{
    background-color: white;
    margin-top: 11vh;
    width: 90%;
    margin: 0 auto;
}
.user-banner{
    position: relative;
    margin: 0 auto;
    top: -30px;
    background-size: cover; /* hiển thị hình nền sao cho toàn bộ phần tử được phủ kín */
    background-position: center; /* căn chỉnh hình nền ở giữa phần tử */
    width: 100%; /* đảm bảo rằng phần banner có chiều rộng tương đương với khu vực hiển thị */
   
}



.user-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Đảm bảo hình ảnh được hiển thị đúng tỷ lệ */
    transition: transform 0.8s ease; /* Hiệu ứng transform với tốc độ 0.8 giây */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Một overlay mờ màu đen */
    opacity: 0; /* Ẩn ban đầu */
    transition: opacity 0.5s ease; /* Hiệu ứng opacity với tốc độ 0.5 giây */
  }
  
  .user-banner:hover .overlay {
    opacity: 1; /* Khi di chuột qua, overlay sẽ hiển thị */
  }
  
  .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Canh giữa văn bản */
    text-align: center;
    color: white;
    z-index: 1; /* Đảm bảo văn bản hiển thị trên hình ảnh */
    opacity: 0; /* Ẩn ban đầu */
    transition: opacity 0.5s ease; /* Hiệu ứng opacity với tốc độ 0.5 giây */
  }
  
  .user-banner:hover .banner-text {
    opacity: 1; /* Khi di chuột qua, văn bản sẽ hiển thị */
  }

  .banner-text button  {
   width: auto;
   height: auto;
   background:none;
   border: 1px solid white;
   padding: 10px 12px;
   margin-top: 20px;
   cursor: pointer;
   }
  .banner-text button #Link {
    color: white;
    font-weight: 100;
    font-size: 0.8vw;

  }

  .banner-text button #Link:hover{
    color: grey;
  }


  @media only screen and (max-width: 954px) {
    .user-banner{
        height: 60vh;
      
    }
    .user-banner img {
        width: 100%;
        padding: 0%;
      }
    
}

@media only screen and (max-width: 800px) {
    

    .user-banner{
        height: 60vh;
       
       
    }
    .user-banner img {
        width: 100%;
        padding: 0% 0;
      }
    


      .banner-text {
        font-size: 2vw;
       }
     
}


@media only screen and (max-width: 662px) {
    

    .user-banner{
        height: 50vh;
     
       
    }
    .user-banner img {
        width: 100%;
        padding: 0% 0;
      }
    


      .banner-text {
        font-size: 2vw;
       }
     
}

@media only screen and (max-width: 564px) {
    

    .user-banner{
        height: 40vh;
      
       
    }
    .user-banner img {
        width: 100%;
        padding: 0% 0;
      }
    


      .banner-text {
        font-size: 2vw;
       }
     
}

@media only screen and (max-width: 400px) {
    

    .user-banner{
        height: 35vh;
      
       
    }
    .user-banner img {
        width: 100%;
        padding: 0% 0;
      }
    


      .banner-text {
        font-size: 2vw;
       }
     
}

.container-slide{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-slide h1{
   font-size: 2.7vw;
   position: relative;
   top: -30px;
 
}
.container-left{
    font-size: 36px;
    width: 30%;
    display: flex;
    height: 30vh;
  justify-content: center;
  align-items: center;

}
.container-right {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 70%;
    height: 30vh;
    
}

.container-right .container {
    position: relative; 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    
}

.categories-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease-in-out;
    justify-content: center;
}



.category {
    flex: 0 0 12%;
    padding: 5px;
    margin: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.category.active {
    opacity: 1;
    transform: translateX(0);
    animation: slideInRight 0.5s ease-in-out;
}

.category.previous {
    animation: slideOutLeft 0.5s ease-in-out;
}

.image-category {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    /* background-color: #ddd; */
    margin-bottom: 10px;
}

.image-category img{
    border-radius: 50%;
    width: 100%;
    
}
.name-category {
    margin-top: 30px;
    text-align: center;
    font-weight: bold;
   
}



.button-container button {
    padding: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a1a1a;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    transition: background-color 0.3s ease-in-out;
}

.button-container button:hover {
  background-color: rgb(54, 54, 54);
  color: white;
}

#button-left{
    position: absolute;
    left:20px;
    top:30%;
    font-weight: bold;
}

#button-right{
    position: absolute;
    right:20px;
    top:30%;
}



.category:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.081);
}


@media only screen and (max-width: 954px) {
   
    .container-slide{
     
        display: block;
      
    }
    .container-left{
        font-size: 36px;
        width: 100%;
        display: flex;
        height: 50px;
        margin: 2% 0;
    }
    .container-left h1{
       font-size: 5vw;
    }
    .container-right{
        font-size: 36px;
        width: 100%;
        display: flex;
        position: relative;
        top: -50px;
    }
    #button-left{
        display: none;
    }
    
    #button-right{
      display: none;
    }
    
}

@media only screen and (max-width: 538px){
    .category {
        flex: 0 0 12%;
        padding: 5px;
        margin: 2px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .image-category {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        /* background-color: #ddd; */
        margin-bottom: 10px;
    }
    
    .image-category img{
        border-radius: 50%;
        width: 100%;
        
    }
    .name-category {
      font-size: 2vw;
       
    }

   
}


  .container-new-product{
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    background-color: white;
}

.title-new-product {
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
}

.scroll-wrapper {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: scroll 19s linear infinite;
}

.scroll-text {
    display: inline-block;
    opacity: 0; /* Không hiển thị chữ ban đầu */
    animation: appearAndScroll 4s linear infinite; /* Áp dụng animation */
    animation-delay: calc(var(--delay) * 0.10s); /* Tính toán độ trễ */
    margin-right: 200px; /* Khoảng cách giữa các chữ */
    font-size: 4vw;
    color: white; /* Màu chữ trắng */
    text-shadow: 2px 2px 0 black,   /* Đổ bóng đen với độ dày là 2px */
                 -2px 2px 0 black,
                 2px -2px 0 black,
                 -2px -2px 0 black;
}

@keyframes appearAndScroll {
    0% {
        transform: translateX(50%);
        opacity: 0.8; /* Bắt đầu ẩn đi */
    }
    50% {
        transform: translateX(-50%);
        opacity: 1; /* Hiển thị chữ */
    }
    100% {
        transform: translateX(-200%);
        opacity: 1; /* Giữ chữ hiển thị */
    }
}
@media only screen and (max-width: 954px) {
   
    .scroll-text {
        display: inline-block;
      
        font-size: 8vw;
       
    }
    
}

@media only screen and (max-width: 550px) {
   
    .scroll-text {
        display: inline-block;
      
        font-size: 15vw;
       
    }
    
}
.container-product-show {
    margin: 5vh auto;
}

.container-product-show .center-layout{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.product {
    position: relative;
    width: calc(25% - 20px);
    height: 60vh;
    margin: 0.2vh; 
    display: flex;
    justify-content: center;
}

.product-image {
    position: relative;
    width: 90%;
    height: 50vh; 
    overflow: hidden; 
    display: inline-block;
}

.product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    transition: opacity 0.5s ease; 
    border-radius: 4px;
}

.hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;

}

.product-image:hover .main-image {
    opacity: 0; 
}

.product-image:hover .hover-image {
    opacity: 1; 
  
}

.product-image .sold-out{
    position: absolute;
    top: 0%;
    left: 0;
    padding: 1% 2.5%;
    font-size: 0.8vw;
    background-color: #000;
    color: white;
}

.product-image .favorite {
    position: absolute;
    top: 5px;
    right: 5px;
    color:rgba(197, 197, 197, 0.743); /* Màu của biểu tượng */
    cursor: pointer;
}

.product-image .favorite i {
    font-size: 2vw;
    transition: 0.3s;
}

.product-image .favorite i:hover {
    font-size: 2vw;
    color: rgb(151, 54, 70);
}

.product-button-container {
    position: absolute;
    bottom:40%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.product-image:hover .product-button-container {
    opacity: 1;
    transform: translate(-50%, -10px);
}

.product-button-container .buy-now,
.product-button-container .add-to-cart {
    width: 100%;
    padding: 1vh 3.5vh;
    margin: 0 5px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    font-size: 0.7vw;
}



.product-button-container .buy-now:hover,
.product-button-container .add-to-cart:hover {
   opacity: 0.8;
}

.product-details {
    position: absolute;
    bottom: 2vh;
    left: 0;
    width: 100%;
    text-align: center;
}

.container-color{
    width: 100%;
    height: 2vh;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-color #color{
    cursor: pointer;
   padding:4px 20px;
   border-radius: 14%;
   margin: 0 5px;
    cursor: pointer;
}

.product-name,
.product-price {
    font-size: 1vw;
    margin-bottom: 5px;
    width: 100%;  
}

.product-price {
    color: rgb(0, 0, 0);
    
}


.product-price #gia_khuyenmai{
 position: absolute;
 right: 10%;
}

@media only screen and (max-width: 1350px){
    .product-image .sold-out{
        font-size: 1.5vw;
       }

       #main{
  
        margin-top: 10vh;
      }
    }

@media only screen and (max-width: 1112px){
    .product {
        position: relative;
        width: calc(33.33% - 20px); /* 3 sản phẩm trên 1 dòng */
        height: 60vh;
        margin: 0.2vh; 
        display: flex;
        justify-content: center;
    }
    .product-name,
.product-price {
    font-size: 1.5vw;
    margin-bottom: 5px;
    width: 100%;  
}
.product-image .favorite i {
    font-size: 4vw;

}

.product-image .sold-out{
 font-size: 1.5vw;
}

}

@media only screen and (max-width: 862px){
    .product {
        position: relative; width: calc(50% - 20px); /* Chiếm khoảng 50% chiều rộng, với một chút không gian trừ đi */
        height: 61vh;
        margin: 0.2vh 10px; /* Điều chỉnh khoảng cách giữa các sản phẩm */
      
    }

    .product-name,
    .product-price {
    font-size: 2vw;
    margin-bottom: 5px;
    width: 100%;  
}
.product-image .favorite i {
    font-size: 5vw;

}

.product-image .sold-out{
    font-size: 2vw;
   }
   
}


@media only screen and (max-width: 600px){
    .product {
      
        width: calc(50% - 10px); /* Chiếm khoảng 50% chiều rộng, với một chút không gian trừ đi */
        height: 51.5vh;
        margin: 4px 2px; /* Điều chỉnh khoảng cách giữa các sản phẩm */
    
    }
    .product-image {
     
        width: 100%; 
        height: 40vh;
    }
    .product-image img {
     
        width: 90%; 
   
    }

    .product-name,
    .product-price {
    font-size: 2.5vw;
    margin-bottom: 5px;
    width: 100%;  
}
.product-image .favorite i {
    font-size: 5vw;

}

.product-image .favorite {
    position: absolute;
    top: 5px;
    right: 25px;
    color:rgba(197, 197, 197, 0.743); /* Màu của biểu tượng */
    cursor: pointer;
}
.product-image .sold-out{
    font-size: 2.5vw;
   }
   
}

@media only screen and (max-width: 548px){
    .product {
      
        width: calc(50% - 40px); /* Chiếm khoảng 50% chiều rộng, với một chút không gian trừ đi */
        height: 49.5vh;
        margin: 4px 10px; /* Điều chỉnh khoảng cách giữa các sản phẩm */
    
    }
    .product-image {
     
        width: 100%; 
   
    }
    .product-image img {
        width: 100%; 
        height: 40vh;
    }

    .product-name,
    .product-price {
    font-size: 2.5vw;
    margin-bottom: 5px;
    width: 100%;  
}

.product-image .favorite i {
    font-size: 8vw;

}

.product-image .favorite {
    position: absolute;
    top: 5px;
    right: 0;
    color:rgba(197, 197, 197, 0.743); /* Màu của biểu tượng */
    cursor: pointer;
}
.product-image .sold-out{
    font-size:3vw;
   }
   
}

.Container-new {
    width: 100%;
    min-height: 400px;
    background-color: #151514;
    float: none;
  
    display: block;
    padding: 30px 0px;
  }

  .Container-new h1 {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    color: white;
    font-size: 40px;
    font-weight: bolder;
    font-style: italic;
    text-transform: uppercase;
    text-decoration: underline;

  }
  
  .Container-new .Container-News {
    width: 75%;
    min-height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Container-new .Container-News .Box-New {
    position: relative;
    width: 25%;
    height: 350px;
    background-color: black;
    margin: 10px 10px;
    cursor: pointer;
  }
  
  .Container-new .Container-News .Box-New:hover .Image-New  {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .Container-new .Container-News .Box-New:hover .Image-New img {
    filter: brightness(80%);
  }
  
  .Container-new .Container-News .Box-New .Image-New img {
    width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: filter 0.3s ease;
  }
  
  .Container-new .Container-News .Box-New .Read-New {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    opacity: 0.5; /* Đảm bảo chữ không bị ảnh hưởng bởi hiệu ứng hover */
    transition: opacity 0.3s ease;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: black;
    color: black;
    font-size: 26px;
    font-weight: bolder;
    font-style: italic;
    text-transform: uppercase;
  }
  
  .Container-new .Container-News .Box-New:hover .Read-New {
    opacity: 1;
    filter: brightness(100%);
    transition: opacity 0.3s ease, color 0.3s ease; /* Thêm hiệu ứng chuyển đổi màu chậm */
    text-decoration: underline;
    color: white;
  }
  
  .Container-new .Container-News .Box-New .Name-New {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    opacity: 0.7888; /* Đảm bảo chữ không bị ảnh hưởng bởi hiệu ứng hover */
    transition: opacity 0.3s ease;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: #F4F2EE;
    color: black;
    font-size:26px ;
    font-weight: bolder;
    font-style: italic;
    text-transform: uppercase;
}

.Container-new .Container-News .Box-New:hover .Name-New {
    opacity: 0;
}

@media only screen and (max-width: 1316px) {
    .Container-new h1 {
        font-size: 25px;
      
      }

    .Container-new .Container-News {
        margin: 0 auto;
        display: contents;
      
      }

      .Container-new .Container-News .Box-New {
        position: relative;
        width: 100%;
        margin: 20px 0px;
        
      }
      .Container-new .Container-News .Box-New .Name-New  a{
      font-size: 4vw;
    }

    .product-price #gia_khuyenmai{
        position: absolute;
        right: 0;
        font-size: 1.35vw; 
       }
       
       .product-price span{
      
        font-size: 1.35vw; 
       }

       .Container-new .Container-News .Box-New {
        margin: 20px auto;
        width: 70%;
      }
      
      .Container-new .Container-News .Box-New  {
        margin: 20px auto;
        width: 60%;
      }
      
   
      .Container-new .Container-News .Box-New .Image-New img {
        width: 100%;
        padding: 0%;
      }
      
 
}
    
@media only screen and (max-width: 642px) {
    .product-price #gia_khuyenmai{
        position: absolute;
        right: 0;
        font-size: 2.2vw; 
       }
       
       .product-price span{
      
        font-size: 2.2vw; 
       }
}

@media only screen and (max-width: 400px) {
    .product-price #gia_khuyenmai{
        position: absolute;
        right: 0;
        font-size: 2.4vw; 
       }
       
       .product-price span{
      font-size: 2.4vw; 
       }
}



.boxfooter1{
    width: 100%;
    height: 100px;
    padding: 20px 0;
    /* background-color: #f1f1f1; */
    display: flex;
    padding-top: 50px;
    align-items: center;
 }

 .boxfooter1 p{
  margin-left: 15px;
  font-size: 1.3vw;
}

.boxfooter1 p span{
  color: red;
}
