@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    
    font-family: 'Montserrat', sans-serif;
    font-size: 1vw;
   
}
/* body{
    background-color: #00000079;
} */

a{
    color: #000;
    text-decoration: none;
 
}


.user-container-header.darken {
    filter: brightness(30%); /* Áp dụng bộ lọc tối cho user-container-header */
}

#main.darken {
    filter: brightness(30%); /* Áp dụng bộ lọc tối cho main */
}

footer.darken{
    filter: brightness(30%); /* Áp dụng bộ lọc tối cho main */
}

/* Home.css */
#notify-add-to-cart {
    width: 400px;
    height: 200px;
    display: none; 
    position: fixed;
    top: 30%; 
    right: 39%; 
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.818);
    font-family: 100;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index:999;
    animation: slideInFromBottom 0.5s ease-in-out forwards;
    text-align: center;
 
}

#notify-add-to-cart #close-notify {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
}

#notify-add-to-cart #close-notify:hover {
  color: red;
}
#notify-add-to-cart  .content-addtocart{
    width: 98%;
    height: 98%;
    background-color: red;
    margin: 0 auto;
    display: flex;
}

#notify-add-to-cart  .content-addtocart .left{
    width: 30%;
    background-color: #666;
}

#notify-add-to-cart  .content-addtocart .right{
    width: 70%;
    background-color: #4b2a2a;
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Loader.css */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 14%;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    border-top: 8px solid transparent;
    border-right: 8px solid #000; /* Black */
    border-bottom: 8px solid #000; /* Black */
    border-left: 8px solid #000; /* Black */
}

@keyframes spin {
    0% { transform: rotate(0deg); border-top-color: rgba(0, 0, 0, 0.5); }
    25% { border-right-color: rgba(0, 0, 0, 0.5); }
    50% { border-bottom-color: rgba(0, 0, 0, 0.5); }
    75% { border-left-color: rgba(0, 0, 0, 0.5); }
    100% { transform: rotate(360deg); border-top-color: transparent; }
}

.error-container {
    margin-top: 120px;
    text-align: center;
}

.error-container h1 {
    font-size: 4rem;
    color: #333;
}

.error-container p {
    font-size: 1.2rem;
    color: #666;
}

.error-container  .button {
    display: inline-block;
    font-size: 1.2vw;
    color: #000000;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.error-container .button:hover {
    text-decoration: underline;
}

/* Thanhs cuộn dọc */
::-webkit-scrollbar {
    width: 8px; /* Chiều rộng của thanh cuộn */
    border-radius: 20px;
    scroll-behavior: smooth;
}

/* Đường viền của thanh cuộn */
::-webkit-scrollbar-track {
    background-color: black;
    scroll-behavior: smooth;
}

/* Thanh cuộn bên trong */
::-webkit-scrollbar-thumb {
    background: #ffffff96; /* Màu nền của thanh cuộn khi hover */
    border-radius: 50px; /* Độ cong của thanh cuộn */
    border: none;
    transition: 20s scroll;
    scroll-behavior: smooth;
}

/* Thanh cuộn bên trong khi hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Màu nền của thanh cuộn khi hover */
}


.container-category__in{
    position: relative;
    /* top: -20px; */
    width: 70%;
    min-height: 200px;
   
    margin:0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-category__in .box-category {
    width: 12%;
    height: 90%;
    margin: 0 10px;
    position: relative; /* Đặt vị trí tương đối để xử lý các phần tử con */
  }
  
  .box-category:hover .box-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Màu nền khi hover */
    z-index: 1; /* Đảm bảo layer trên cùng */
    transition: opacity 0.3s ease; /* Hiệu ứng chuyển đổi */
    opacity: 0.7; /* Độ mờ mặc định */
  }
  
  .box-category .box-img {
    position: relative; /* Đặt vị trí tương đối để xử lý các phần tử con */
  }
  
  .box-category .box-img img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .box-category .box-name {
    text-align: center;
    margin-top: 5px;
  }
  
  .box-category .box-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Màu nền mặc định */
    z-index: 1; /* Đảm bảo layer trên cùng */
    transition: opacity 0.3s ease; /* Hiệu ứng chuyển đổi */
    opacity: 0; /* Ẩn mặc định */
  }
  
/* Lớp phủ modal */
.modal-overlay {
    position: fixed; /* Giữ modal luôn trên cùng */
    top: 0;
    left: 0;
  
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Màu tối nhẹ để làm mờ nền */
    display: flex;
    justify-content: center;
    align-items: center; /* Đặt modal vào giữa */
    z-index: 1000; /* Đảm bảo modal nằm trên cùng */
  }
  
  /* Nội dung modal */
  .modal-content {
    position: relative;
    width: 500px;
    height: 240px;
    background: rgb(255, 255, 255);
    padding: 10px;
    
    border-radius: 10px; /* Bo góc cho modal */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Đổ bóng */
    animation: fadeIn 0.3s ease-in-out; /* Hiệu ứng khi modal xuất hiện */
  }
  
  

  .modal-content .container{
    display: flex;
  }

  .modal-content h1{
    position: relative;
    text-align: center;
    top: -6px;
    font-size: 0.7vw;
    color: rgba(112, 112, 112, 0.647);
    word-wrap: normal;
    vertical-align: sub;
  }


  .modal-content h1 i{
    vertical-align: middle;
  }
    /* Nội dung modal */
    .modal-content .left {
        width: 30%;
        height: 100%;
        background-color: #00000006;
        display: flex;
        justify-content: center;
            align-items: center;
      }
      .modal-content .left img{
       width: 90%;
      }

      .modal-content .right {
        width: 70%;
        height: 100%;
        /* background-color: #706d6d; */
      }

      .modal-content .right .name-product {
        width: 100%;
        font-size: 1vw;
        margin-left: 15px;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .modal-content .right .price-product {
        width: 100%;
        font-size: 1vw;
        margin-left: 15px;
        margin-bottom: 12px;
        
      }

      .modal-content .right .color-product {
        width: 100%;
        font-size: 1vw;
        margin-left: 15px;
        margin-bottom: 12px;
        
      }
      .modal-content .right #select-size{
       border: none;
       outline: none;
      }

  
  /* Hiệu ứng cho modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9); /* Giảm kích thước ban đầu */
    }
    to {
      opacity: 1;
      transform: scale(1); /* Tăng kích thước đến 100% */
    }
  }
  

  .modal-content button:hover {
 color: tomato;
 background: none;
  }

.modal-content #container-button{
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .modal-content #add-to-cart {
    padding: 8px 20px;
    background-color: #000;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.7vw;
    transition: 0.3s;
    margin: 0 3px;
  }

  .modal-content #add-to-cart:hover {
    border:  1px solid black;
    background: none;
    color: black;
  }

.container-search{
  
  width: 100%;
  display: none;
}

.container-search h1{
 text-align: center;
}

.container-search{
  display: none;
}
@media (max-width: 1350px) {
  .container-search{
    display: block;
  }
  .container-search h1{
    font-size: 2vw;
    margin-bottom: 20px;
   }

   .container-search .box-search-component{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
   }
   .container-search .box-search-component input{
   width: 80%;
   padding: 20px 20px;
   border-radius: 4px;
   font-size: 1.5vw;
   outline: none;
   border: none;
   background-color: #000000bb;
   color: white;
  
   }

   .container-search .box-search-component i{
  position: absolute;
  right: 12%;
  font-weight: bold;
  color: white;
  font-size: 3vw;
  cursor: pointer;
    }
}
@media (max-width: 600px) {
  .container-search .box-search-component input{
    width: 100%;
    }

    .container-search .box-search-component i{
      position: absolute;
      right: 2%;
      font-weight: bold;
      color: white;
      font-size: 3vw;
      cursor: pointer;
        }
}






