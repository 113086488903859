

footer{
    background-color: #212121;
    min-height: auto;
    width: 100%;
    padding-top: 40px;
    color: white;
    position: relative;
}

.container-footer {
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: center;
}

footer p{
    font-weight: bold;
    font-size: 0.8vw;
    text-align: left;
}

footer ul,
footer #FacebookProvider{
    text-align: left;
    list-style: none;
}

footer ul li{
    margin: 20px 0;
    text-align: left;
    font-size: 0.7vw;
}
footer .footer-left{
 width: 30%;
text-align: center;
}


footer .footer-center{
    width: 30%;
}



.footer-right{
    width: 30%;
text-align: left;
}

.footer-right ul{
    width: 30%;
display: inline-block;
   margin-bottom: 10px;
   text-align: left;
  }

  .footer-right ul li{
    margin: 10px 5px;
   display: inline-flex;
   font-size: 0.7vw;
  }
  
  
footer li:hover{
    text-decoration: underline;
}


.fb-page{
    clear: both;
    margin-top: 10px;
}

.footer-bottom{
    
    border-top: 1px solid white;
    width: 100%;
    padding: 10px 0;
    display: block;
}

.footer-bottom p{
    font-weight: 100;
    font-size: 9px;
    text-align: center;
}

@media only screen and (max-width: 947px) {
    .container-footer {
    display: flex; /* Sử dụng Flexbox */
    flex-direction: column; /* Sắp xếp theo chiều dọc */
    font-size: 3vw;
    }
    .container-footer p{
        font-size: 4vw;
        text-align: center;
    }
    .container-footer li{
        font-size: 3vw;
        margin: 3%;
        
    }
    footer .footer-left{
        width: 100%;
        border-bottom: 1px solid white;
        padding: 20px 0;
        text-align: c;
    }

    footer .footer-center{
        width: 100%;
        border-bottom: 1px solid white;
        padding: 20px 0;
    }

    footer .footer-right{
        padding: 20px 0;
        width: 100%;
    }

}


