
.container-user-detail .content {
   width: 100%;
    margin: 40px auto;
    display: flex;
}

.container-user-detail .box-1 {
    width: 100%;
    margin-left: 10%;
 
}

.container-user-detail .box-1 h1 {
    line-height: 40px;
    font-size: 1.4vw;
}

.container-user-detail .box-1 #dhgn {
  font-size: 0.8vw;
}


.container-user-detail .xinchao {
    margin: 10px 0;
    font-size:0.9vw;
}

.container-user-detail table {
    margin:20px 0;
    width: 100%;
    border-collapse: collapse;
}

.container-user-detail tr {
    border: 1px solid rgb(229, 229, 229);
}

.container-user-detail th {
    text-align: left;
    font-weight: 500;
}

.container-user-detail th,
.container-user-detail td {
    padding: 10px;
    font-size: 0.8vw;
}

.container-user-detail #xct {
    cursor: pointer;
}

.container-user-detail #xct:hover,
.container-user-detail #ctn {
        color: gray;
}

.container-user-detail .page-phantrang {
 width: 95%;
 position: relative;

}
.container-user-detail #one {
    background-color: black;
    color: white;
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 5px;
}

.container-user-detail .box-2 {
    margin-top: 30px;   
    width: 30%;
    margin-left: 2%;
}

.container-user-detail .box-2-mb {
display: none;
}

.container-user-detail .box-2 p {
    margin: 10px 0;
    color: #333;
}

.container-user-detail .box-2 p span {
    font-weight: 505;
    font-size: 0.9vw;
}


.container-user-detail .box-2 button {
    display: block;
    padding: 5px 10px;
    margin: 5px 0;
    background-color: white;
    border: 1px solid rgb(229, 229, 229);
    cursor: pointer;
}

@media(max-width:1400px) {
    .container-user-detail .box-2 p {
      font-size: 1.4vw;
    }
}

@media(max-width:898px) {
    .container-user-detail .content {
        width: 100%;
         display: flex; /* Sử dụng Flexbox */
         flex-direction: column; /* Sắp xếp theo chiều dọc */
         width: 100%; /* Chiếm toàn bộ chiều rộng */
     }
     .container-user-detail .box-1 {
        width: 95%;
        margin: 0 auto
    }

    .container-user-detail .box-1 h1 {
       font-size: 3vw;
    }
    .container-user-detail .box-1 th,
    .container-user-detail .box-1 td {
       font-size: 1.7vw;
    }
    #xct a{
        font-size: 1.5vw;
    }
    .container-user-detail .box-2 {
      display: none;
    }
    .container-user-detail .box-2-mb {
        display: block;
        margin-left: 5%;
        margin-bottom: 2%;
      }

      .container-user-detail .box-2-mb p {
        font-size: 2vw;
        margin-bottom: 5px;
      }

      .container-user-detail .box-2-mb span {
        font-size: 2vw;
        margin-bottom: 5px;
      }


      .container-user-detail .box-2-mb h1 {
        font-size: 3vw;
        margin-bottom: 10px;
      }

      .container-user-detail .box-2-mb button {
        display: block;
        padding: 5px 10px;
        margin: 5px 0;
        background-color: white;
        border: 1px solid rgb(229, 229, 229);
        cursor: pointer;
        font-size: 2vw;
    }
    .container-user-detail .box-2-mb button a {
        font-size: 2vw;
    }
}

.container-user-detail input {
    padding: 15px;
    width: 80%;
    margin: 10px 0;
    background-color: #ededed;
    border: none;
}

.container-user-detail form button {
    display: block;
    margin-top: 10px;
    padding: 13px 24px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 4px;
}

.container-user-detail form button:hover {
    opacity: 0.7;
}